import React from 'react';
import './Intro.css'

function Intro() {
        return (
            <div className="container lead">
                <div className="row mt-3">
                    <div className="col my-auto text-center">
                        <img src="/me.png" alt="Maciej Lewinski" className="avatar" />
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col my-auto text-center description">
                        <p>Hi there!</p>
                        <p className="text-muted">I'm Maciej, Software Developer from Poland, with experience ranging from development of Android apps to various backend stacks, mainly modern .NET, Typescript and interest in functional programming.</p>
                        <p className="text-muted">
                            I enjoy working in fast-paced teams, with cutting edge tech. Always open to interesting opportunities. Happy to change tech stacks depending on what's needed.
                        </p>
                    </div>
                </div>
            </div>
        );
}

export default Intro;