import React from 'react';
import './App.css';
import Intro from './Components/Intro/Intro'
import Contact from './Components/Contact/Contact';
import ScrollToTop from './Components/ScrollToTop/ScrollToTop';

function App() {
  return (
    <div className="app container">

      <div className="row">
        <div className="col"></div>
        <div className="col-10">

          <div className="row">
            <div className="col">
              <Intro />
            </div>
          </div>

          <div className="row">
            <div className="col">
              <Contact />
            </div>
          </div>

          <div className="row">
            <div className="col">
              <hr />
            </div>
          </div>

        </div>
        <div className="col"></div>
      </div>
      <footer>
        <ScrollToTop />
      </footer>
    </div>
  );
}

export default App;
