import React from 'react';
import './Contact.css'

function Contact() {
    return (
        <div className="container">
            <div className="row mt-3">
                <div className="col my-auto text-center ">
                    <a href="mailto: maciej@lewinski.dev">maciej@lewinski.dev</a>
                </div>
            </div>

            <div className="row mt-3">
                <div className="col my-auto text-end mr-1">
                    <a href="https://github.com/lewinskimaciej"><img src="/github.svg" className="contact-icon" alt="github" /></a>
                </div>
                <div className="col my-auto text-start ml-1">
                    <a href="https://www.linkedin.com/in/lewinskimaciej"><img src="/linkedin.png" className="contact-icon" alt="linkedin" /></a>
                </div>
            </div>
        </div>
    );
}

export default Contact;